import { useEffect, useState } from 'react';

const useScrolled = () => {
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;

      setIsTop(top < 20);
    });
  }, []);
  return isTop;
};

export default useScrolled;
