import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Button from './Button';
import Modal from './Modal';

const ProjectCard = ({ title, techs, img, mp4, demo, source }) => {
  const [isModal, setIsModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const sourceBtn = useRef();
  const demoBtn = useRef();
  //background: `linear-gradient(0deg, rgba(34, 35, 38,0.85) 10%, rgba(33, 34, 37, 0.65) 32%, rgba(34, 35, 38, 0) 126%), url(${img}) center top / cover`,

  return (
    <>
      <Modal
        onClose={() => setIsModal(false)}
        open={isModal}
        title={title}
        techs={techs}
        img={img}
        demo={demo}
        source={source}
        mp4={mp4}
      />

      <motion.div
        className="card"
        whileHover={{
          scale: 1.02,
        }}
        initial={{
          backgroundImage: `linear-gradient(0deg, rgba(34, 35, 38,0.90) 10%,rgba(34, 35, 38,0.85) 20%, rgba(33, 34, 37, 0.80) 30%, rgba(34, 35, 38, 0) 100%), url(${img})`,
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
        }}
        animate
        onClick={(e) => {
          if (e.target !== demoBtn.current && e.target !== sourceBtn.current) {
            setIsModal(true);
          }
        }}
      >
        <div className="card-tab" style={{ zIndex: 2 }}>
          <div className="card-title">{title}</div>
          <div className="card-links">
            <a href={demo} target="_blank" rel="noreferrer">
              <Button ref={demoBtn}>
                <i className="bi-globe2 me-2"></i>
                Visit
              </Button>
            </a>
            {source && (
              <a href={source} target="_blank" rel="noreferrer">
                <Button ref={sourceBtn}>
                  <i className=" bi-github me-2"></i>
                  Source
                </Button>
              </a>
            )}
          </div>
        </div>
        <motion.div
          whileHover={{
            opacity: 0,
          }}
          initial={{ opacity: 0.4, zIndex: 1, backgroundColor: '#323336' }}
          className="card position-absolute"
        />
      </motion.div>
    </>
  );
};

export default ProjectCard;
