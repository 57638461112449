import React, { useState } from 'react';
import Button from '../components/Button';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  if (emailSent) {
    return (
      <div className="my-5">
        <h2>Thank you!</h2>
        <div>We'll be in touch soon.</div>
      </div>
    );
  }
  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const submit = () => {
    if (!isValidEmail(email)) {
      alert('Please type valid email');
      return;
    }
    if (name && email && message) {
      const serviceId = 'service_5qrbz2i';
      const templateId = 'template_n80jobr';
      const userId = 'E_AzcNuzJv6PLbcgu';
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error));

      setName('');
      setEmail('');
      setMessage('');
      setEmailSent(true);
    } else {
      alert('Please fill in all fields.');
    }
  };
  return (
    <>
      <div className="pt-5">
        <input
          className="contact-input"
          type="text"
          placeholder="Your name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          className="contact-input"
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <textarea
          className="contact-textarea"
          placeholder="Your message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <Button onClick={submit} hoverScale={1.05} type="submit">
        Send a message
      </Button>
    </>
  );
};

export default Contact;
