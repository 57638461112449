import React, { useEffect } from 'react';
import NavButton from './components/NavButton';
import { motion } from 'framer-motion';
import Scrollspy from 'react-scrollspy';

const SideNav = ({ current }) => {
  return (
    <motion.div
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: 'spring', damping: 10, duration: 0.5, delay: 0.2 }}
      className="side-nav d-none d-md-block col-4"
    >
      <Scrollspy
        items={['welcome', 'skills', 'experience', 'about', 'contact']}
        componentTag="div"
        className="side-nav-wrapper"
        currentClassName="current"
        offset={-150}
      >
        <NavButton color="#d7c5dd" text="Welcome" />
        <NavButton color="#f7e8d4" text="Skills" />
        <NavButton color="#cae8e4" text="Experience" />
        <NavButton color="#d5e3c3" text="About" />
        <NavButton color="#e3c3c3" text="Contact" />
      </Scrollspy>
    </motion.div>
  );
};

export default SideNav;
