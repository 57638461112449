import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './Button';

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#1d1e21',
  width: '90%',
  maxWidth: '900px',
  borderRadius: '10px',
  maxHeight: '100vh',
  zIndex: 1000,
  overflowY: 'auto',
};

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
};

export default function Modal({
  open,
  demo,
  source = null,
  onClose,
  title,
  techs,
  mp4,
}) {
  const [videoLoading, setVideoLoading] = useState(true);

  const onVideoCanPlay = () => {
    setVideoLoading(false);
  };

  useEffect(() => {
    if (open) document.body.style.overflowY = 'hidden';
    if (!open) {
      setVideoLoading(true);
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [open]);

  return ReactDom.createPortal(
    <AnimatePresence initial={false}>
      {open && (
        <>
          <div style={OVERLAY_STYLES} onClick={onClose} />
          <motion.div
            style={MODAL_STYLES}
            initial={{ transform: 'translate(-50%, 0)', opacity: 0 }}
            animate={{ transform: 'translate(-50%, -50%)', opacity: 1 }}
            exit={{
              opacity: 0,
            }}
          >
            <motion.i
              onClick={onClose}
              className="bi bi-x-lg fs-3 float-end py-2 me-3 fw-bolder"
              whileHover={{ cursor: 'pointer' }}
            ></motion.i>
            {videoLoading && (
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: '400px' }}
              >
                <div className="spinner-border text-white" role="status">
                  <span className="visually-hidden">Loading...</span>
                  <svg
                    className="bi bi-arrow-repeat"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  ></svg>
                </div>
              </div>
            )}
            {
              <video
                className="img-fluid"
                style={{ display: videoLoading ? 'none' : 'block' }}
                preload="auto"
                autoPlay={true}
                muted={true}
                playsInline={true}
                loop={true}
                onCanPlay={onVideoCanPlay}
              >
                <source src={mp4} type="video/mp4" />
              </video>
            }
            <div className="card-tab">
              <div className="card-title">{title}</div>
              <div className="card-techs">
                {techs.map((tech) => (
                  <div key={tech}>{tech}</div>
                ))}
              </div>
              <div className="card-links">
                <a href={demo} target="_blank" rel="noreferrer">
                  <Button>
                    <i className="bi-globe2 me-2"></i>
                    Visit
                  </Button>
                </a>
                {Boolean(source) && (
                  <a href={source} target="_blank" rel="noreferrer">
                    <Button>
                      <i className=" bi-github me-2"></i>
                      Source
                    </Button>
                  </a>
                )}

                {title === 'Messaging app' && (
                  <>
                    <div className="me-3 mt-2 text-nowrap d-flex align-items-center">
                      <i className="bi bi-info-circle me-1 fs-4"></i>
                      Accounts for Demo:
                    </div>
                    <div className="text-nowrap fs-6 mt-2 ">
                      Username: thomas/santiago
                      <br />
                      Password: 123456
                    </div>
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById('modal')
  );
}
