import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
const Button = (props, ref) => {
  const motionBtn = {
    initial: { background: 'rgba(250, 250, 250, 0)', color: 'white' },
    hover: {
      background: 'rgb(250, 250, 250)',
      color: 'black',
      cursor: 'pointer',
      scale: props.hoverScale || 1,
    },
  };
  return (
    <motion.button
      variants={motionBtn}
      initial="initial"
      whileHover="hover"
      className={`action-btn  ${props.addClass || ''}`}
      ref={ref}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </motion.button>
  );
};

export default forwardRef(Button);
