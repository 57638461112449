import React, { useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useScrolled from '../hooks/useScrolled';
const Intro = ({ current, setCurrent }) => {
  const isTop = useScrolled();
  const ref = useRef();

  return (
    <motion.div
      id="welcome"
      className="intro"
      initial={{ x: +100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: 'spring', damping: 10, duration: 0.5, delay: 0.2 }}
    >
      <div ref={ref} className="intro-text display-6 ">
        Looking for an awesome front-end dev with solid skills, excellent
        teamwork, who learns quickly?
        <h2 className="h2 fw-bold" style={{ marginTop: '20px' }}>
          Check out my work and contact me.
        </h2>
      </div>

      {isTop && (
        <motion.div
          className="d-flex scroll-icon justify-content-center w-100"
          animate={{ y: 20 }}
          initial={{ opacity: 1 }}
          transition={{
            repeatType: 'reverse',
            repeat: Infinity,
            duration: 1,
          }}
        >
          <i
            className="bi bi-chevron-compact-down display-1"
            onClick={() => {
              window.scrollTo({
                top: document.getElementById('skills').offsetTop - 200,
                behavior: 'smooth',
              });
            }}
          ></i>
        </motion.div>
      )}
    </motion.div>
  );
};

export default Intro;
