import React from 'react';
import Intro from './sections/Intro';
import AboutMe from './sections/AboutMe';
import Projects from './sections/Projects';
import Experience from './sections/Experience';
import Section from './components/Section';
import Contact from './sections/Contact';
import Skills from './sections/Skills';
import { motion } from 'framer-motion';
import useScrolled from './hooks/useScrolled';
const MainContainer = ({ onProjectClick }) => {
  const isTop = useScrolled();

  return (
    <div
      className="main-container col-12 col-md-8 overflow-hidden"
      style={{ maxWidth: '800px' }}
    >
      <Intro />
      <motion.div
        animate={{
          opacity: isTop ? 0 : 1,
          transition: {
            duration: 0.3,
          },
        }}
        initial={{
          opacity: 0,
        }}
      >
        <Section title="Skills">
          <Skills />
        </Section>
        <Section title="Experience">
          <Experience />
        </Section>
        <Section title="About">
          <AboutMe />
        </Section>
        <Section title="Contact">
          <Contact />
        </Section>
      </motion.div>
    </div>
  );
};

export default MainContainer;
