import React from 'react';
import IconSkill from '../components/IconSkill';

const Skills = () => {
  return (
    <div style={{ width: '120%' }}>
      <div className="skill-icons mt-4">
        <IconSkill skill="HTML" uppercase />
        <IconSkill skill="CSS" uppercase />
        <IconSkill skill="JavaScript" />
        <IconSkill skill="React" />
        <IconSkill skill="Redux" />
        <IconSkill skill="NextJS" />
        <IconSkill skill="Vue" />
        <IconSkill skill="Pinia" />
        <IconSkill skill="Inertia" />
        <IconSkill skill="Tailwind" />
        <IconSkill skill="MaterialUI" />
        <IconSkill skill="Bootstrap" />
        <IconSkill skill="Figma" />
        <IconSkill skill="Auth0" />
        <IconSkill skill="Firebase" />
        <IconSkill skill="NodeJS" />
        <IconSkill skill="PHP" />
        <IconSkill skill="Laravel" />
        <IconSkill skill="Framer" />
        <IconSkill skill="SwiftUI" />
        <IconSkill skill="Xcode" />
        <IconSkill skill="Github" />
        <IconSkill skill="Copilot" />
        <IconSkill skill="ViteJS" />
        <IconSkill skill="Cloudinary" />
        <IconSkill skill="Netlify" />
        <IconSkill skill="Docker" />
        <IconSkill skill="Cypress" />
      </div>
    </div>
  );
};

export default Skills;
