import React from 'react';

const IconSkill = ({ skill, uppercase }) => {
  return (
    <div className="d-flex flex-column justify-content-center m-2 img-fluid">
      <img
        src={`./icons/${skill}.svg`}
        alt={`${skill}-icon`}
        className="skill-img h-10 w-10"
      />
      <div className="text-center mt-1">{skill}</div>
    </div>
  );
};

export default IconSkill;
