import React from 'react';
import IconSkill from '../components/IconSkill';
import { motion } from 'framer-motion';
import Button from '../components/Button';
import ProjectCard from '../components/ProjectCard';

const Experience = () => {
  return (
    <div className="resume">
      <div className="job-block  w-sm-75" style={{ marginTop: '42px' }}>
        <ProjectCard
          title="I Love Your Stories"
          techs={[
            'Vue 3',
            'Pinia',
            'Inertia',
            'Tailwind CSS',
            'Laravel',
            'PHP',
            'SwiftUI',
            'Xcode',
            'WatchOS',
            'iOS',
            'Localization',
            'Mobile UI',
            'Github',
            'Docker',
            'Copilot',
            'ChatGPT',
            'Vite',
            'Cypress',
            'CI/CD',
            'Javascript',
            'HTML',
            'CSS',
          ]}
          img="./images/ilys.png"
          mp4="./mp4/ilys-demo.mp4"
          demo="https://ilys.com/"
        />
        <div className="content">
          <h6 style={{ marginTop: '36px' }} className="fs-5 fw-bold">
            Responsibilities:
          </h6>

          <ul>
            <li>
              Led the rebuild of the entire front-end platform Collaborated with
              product, back-end, and dev-ops teams.
            </li>
            <li>
              Designed and implemented a Mobile UI/UX strategy and front-end
              migration plan.
            </li>
            <li>
              Conducted customer interviews via Zoom, presenting the new system.
            </li>
            <li>
              Managed Localization, including Right-To-Left (RTL) languages.
            </li>
            <li>
              Developed a native iOS companion app with Apple Watch connectivity
              using SwiftUI.
            </li>
            <li>
              Ensured quality by fixing issues and implementing a testing system
              for CI/CD
            </li>
          </ul>

          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Technologies:
          </h6>
          <p>
            UI/UX, Vue 3, Pinia, Localization, Tailwind CSS, Laravel, PHP,
            SwiftUI, Xcode, WatchOS, iOS, Inertia, Mobile UI, Github, Docker,
            Copilot, ChatGPT, Vite, Cypress, CI/CD, Javascript, HTML, CSS.
          </p>
        </div>
      </div>

      <hr className="hr" style={{ marginTop: '80px', marginBottom: '80px' }} />

      <div className="job-block  w-sm-75">
        <ProjectCard
          title="Assetto Depot"
          techs={[
            'Next.JS',
            'React',
            'Redux',
            'Supabase',
            'Cloudinary',
            'Material UI',
            'Node',
            'Nodemailer',
            'API Design',
          ]}
          img="./images/assetto-depot.png"
          mp4="./mp4/assettodepot-demo.mp4"
          source="https://github.com/mikeMisha/assetto-depot"
          demo="https://assettodepot.com/"
        />
        <div className=" content">
          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Responsibilities:
          </h6>

          <ul>
            <li>
              Determined community needs, designing software solutions to
              fulfill them.
            </li>
            <li>
              Created an interactive online repository for digital assets of a
              popular online racing game.
            </li>
            <li>
              Optimized the platform for quick user experience and intuitive
              UX/UI.
            </li>
            <li>
              Implemented Server-Side-Rendering (SSR) using a serverless
              tech-stack.
            </li>
            <li>
              Developed a native iOS companion app with Apple Watch connectivity
              using SwiftUI.
            </li>
            <li>
              Ensured quality by fixing issues and implementing a testing system
              for CI/CD
            </li>
          </ul>

          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Technologies:
          </h6>
          <p>
            SSR with Next.JS, React, Redux Toolkit, Supabase, Cloudinary,
            Material UI, Node, Nodemailer, API Design.
          </p>
        </div>
      </div>

      <hr className="hr" style={{ marginTop: '80px', marginBottom: '80px' }} />

      <div className="job-block  w-sm-75">
        <ProjectCard
          title="Spelling Practice App"
          techs={[
            'React',
            'Bootstrap 5',
            'Redux',
            'Framer Motion',
            'Auth0',
            'Netlify',
            'Firebase/Firestore',
            'Javascript',
            'CSS',
            'HTML',
            'Github',
          ]}
          img="./images/pms-project.png"
          mp4="./mp4/spelling-demo.mp4"
          source="https://github.com/mikeMisha/practice-my-spelling"
          demo="https://practicemyspelling.com/"
        />
        <div className="content">
          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Responsibilities:
          </h6>

          <ul>
            <li>
              Developed a niche educational application, crafting UX/UI for
              optimal user outcomes.
            </li>
            <li>Implemented a full-stack serverless platform.</li>
            <li>Managed feedback for continuous improvement.</li>
          </ul>

          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Technologies:
          </h6>
          <p>
            React, Bootstrap 5, Redux Toolkit, Framer Motion, Auth0, Netlify,
            Firebase/Firestore, Javascript, CSS, HTML, Github.
          </p>
        </div>
      </div>

      <hr className="hr" style={{ marginTop: '80px', marginBottom: '80px' }} />

      <div className="job-block  w-sm-75">
        <ProjectCard
          title="Coastal Linen Supply"
          techs={[
            'Wordpress',
            'WooCommerce',
            'PHP',
            'jQuery',
            'Divi',
            'CSS',
            'Javascript',
            'HTML',
          ]}
          img="./images/coastal-linen.png"
          mp4="./mp4/coastal-linen-demo.mp4"
          demo="https://coastallinensupply.com/"
        />
        <div className="content">
          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Responsibilities:
          </h6>

          <ul>
            <li>
              Interviewed business operators to define needs, reviewing
              processes to find growth and efficiency opportunities.
            </li>
            <li>
              Selected the technology stack, designing and developing an online
              platform for commerce and marketing.
            </li>
            <li>
              Successfully reduced operating costs and increased revenue through
              efficient practices and customer satisfaction.
            </li>
          </ul>

          <h6 className="fs-5 fw-bold" style={{ marginTop: '36px' }}>
            Technologies:
          </h6>
          <p>
            Wordpress, WooCommerce, PHP, jQuery, Divi, CSS, Javascript, HTML.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Experience;
