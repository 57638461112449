import React from 'react';
import Button from '../components/Button';

const AboutMe = () => {
  return (
    <React.Fragment>
      <div className="content fs-5">
        For me, it's not just about code. Whether I'm tackling a challenging
        coding problem, exploring a new hobby, or navigating life's hiccups, I'm
        all in. I'm always on the lookout for the next thing to learn or the
        next challenge to conquer. For me, it's about giving everything the best
        I have. Simply put, I'm here to make things happen and have a blast
        doing it.
        <br />
        <br />
        If you want to know more about my interests and hobbies, open my resume
        below.
      </div>

      <div className="mt-3 d-flex  flex-wrap gap-3">
        <a href="./pdf/MichaelKarabachResume.pdf" target="_blank">
          <Button hoverScale={1.05} addClass="me-3 fs-6ß ">
            View resume
            <i className="bi-filetype-pdf fs-4 mx-2"></i>
          </Button>
        </a>
        <a
          href="./pdf/MichaelKarabachResume.pdf"
          download="MichaelKarabachResume"
        >
          <Button hoverScale={1.05} addClass="fs-6  ">
            Download resume
            <i className="bi-filetype-pdf fs-4 mx-2"></i>
          </Button>
        </a>
      </div>
    </React.Fragment>
  );
};

export default AboutMe;
