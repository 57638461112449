import React, { useRef, useEffect, forwardRef, useCallback } from 'react';
import { motion } from 'framer-motion';

const Section = ({ children, title, current, setCurrent }) => {
  const ref = useRef();
  /*
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= 200 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset < 200) {
        if (current !== 'Intro') {
          setCurrent('Intro');
        }
      }
      if (isInViewport(ref.current)) {
        if (current !== title) {
          setCurrent(title);
        }
      }
    });
  }, []);
  */
  return (
    <div id={title.toLowerCase().replace(/ /g, '-')} className="section">
      <motion.div ref={ref} className="section-title display-6">
        {title}
      </motion.div>
      {children}
    </div>
  );
};

export default Section;
