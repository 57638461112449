import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import useScrolled from '../hooks/useScrolled';

const NavButton = ({ text, color, className }) => {
  const [isCurrent, setIsCurrent] = useState();
  const isTop = useScrolled();

  const onClick = () => {
    const yOffset = -50;
    const elm = document.getElementById(text.toLowerCase().replace(/ /g, '-'));
    const y = elm.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    className === 'current' ? setIsCurrent(true) : setIsCurrent(false);
  }, [className]);

  return (
    <motion.button
      className="btn"
      animate={{
        minWidth: isTop ? '0px' : '100%',
        backgroundColor: isCurrent || isTop ? color : color + '00',
        color: isCurrent || isTop ? 'black' : 'white',
        transition: {
          duration: 0.2,
        },
      }}
      initial={{
        color: 'black',
        backgroundColor: color,
      }}
      whileHover={{
        backgroundColor: isCurrent ? color : color + '66',
        transition: {
          duration: 0.1,
        },
      }}
      onClick={() => onClick()}
    >
      {text}
    </motion.button>
  );
};

export default NavButton;
