import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import MainContainer from './MainContainer';
import Modal from './components/Modal';
const App = () => {
  return (
    <>
      <div className="wrapper d-flex">
        <SideNav />
        <MainContainer />
      </div>
    </>
  );
};

export default App;
